@charset "UTF-8";

/*-------BACKGROUND COLORS-------*/
.darkTheme,
.darkTheme .changelog-unread,
.darkTheme .siteSelectionMap,
.darkTheme .dropzone-file-item .file-item,
.darkTheme .tagTablename div:hover,
.darkTheme .menu-toggle-button,
.darkTheme .embedded-docs-clickable:hover,
.darkTheme .react-select__menu,
.darkTheme .pro-sidebar-inner,
.darkTheme .cuttingTools,
.darkTheme .footerBtnGroup,
.darkTheme .form-control,
.darkTheme .rs-picker-toggle,
.darkTheme .rs-popover,
.darkTheme .rs-dropdown-menu,
.darkTheme .more-button-container:hover,
.darkTheme .header-selection div,
.darkTheme .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper,
.darkTheme .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input,
.darkTheme .rs-picker-select-menu-item-active,
.darkTheme .rs-picker-menu-group-title:hover,
.darkTheme .rs-picker-select-menu-item:hover,
.darkTheme .rs-input,
.darkTheme .rs-check-item:not(.rs-checkbox-disabled):hover,
.darkTheme .btn-secondary,
.darkTheme .rs-slider-bar,
.darkTheme .rs-btn-disabled,
.darkTheme .rs-input-number-btn-group-vertical,
.darkTheme .rs-picker-toggle-textbox,
.darkTheme .btn-custom-secondary,
.darkTheme .rs-picker-cascader-menu-item:hover,
.darkTheme .rs-picker-cascader-menu-item-focus,
.darkTheme .rs-picker-cascader-menu-item-active,
.darkTheme .rs-picker-cascader-row:hover,
.darkTheme .rs-picker-cascader-row-focus,
.darkTheme .tagPicker .rs-picker-toggle-wrapper,
.darkTheme .card-footer .unselectedElement,
.darkTheme .global_time_filter .rs-btn,
.darkTheme .row .rs-radio-group
{
  background-color: #2c3034 !important;
}
.darkTheme .table-striped > tbody > tr:nth-of-type(2n+1) > *,
.darkTheme .rs-toggle-presentation {
  background-color: #2c3034;
}
.darkTheme .boa-card-header,
.darkTheme .breadcrumb,
.darkTheme .card-body,
.darkTheme .card-title,
.darkTheme .file-item,
.darkTheme .file-item:hover,
.darkTheme .jsPanel-headerbar,
.darkTheme .table thead,
.darkTheme .card,
.darkTheme .item,
.darkTheme .jsPanel-content,
.darkTheme .embedded-docs-content,
.darkTheme .docviewer,
.darkTheme .docviewer tr.emptyRow,
.darkTheme .docviewer tr:nth-child(2n+1),
.darkTheme .rs-drawer-content,
.darkTheme .rs-dropdown button,
.darkTheme .rs-dropdown ul,
.darkTheme .rs-modal-content,
.darkTheme .rs-modal-custom-full-modal,
.darkTheme .rs-picker-select-menu-item-focus,
.darkTheme .rs-picker-menu,
.darkTheme .rs-input-number,
.darkTheme .rs-input-group-addon,
.darkTheme .statusMonitor .card-title,
.darkTheme .selectedTagsList,
.darkTheme .statusBlockList,
.darkTheme .statusBlockButton,
.darkTheme .metadata-container,
.darkTheme .more-item-container:not(.greyed-out):hover,
.darkTheme .react-select__option--is-focused,
.darkTheme .siteSelection,
.darkTheme .productionControlContent .table,
.darkTheme .productionControlContent .card-body,
.darkTheme .productionControlContent .textbox,
.darkTheme .productionControlContent .infobox-bottom-right,
.darkTheme .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item,
.darkTheme .pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner,
.darkTheme .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon,
.darkTheme .y-axis-toggle-button,
.darkTheme .header-dropdown-item:hover,
.darkTheme .weather,
.darkTheme .selected, 
.darkTheme .selected:hover,
.darkTheme .item-content,
.darkTheme .fileman-breadcrumb .rs-btn,
.darkTheme .rs-tag,
.darkTheme .rs-table-cell,
.darkTheme .rs-table-row-header .rs-table-cell-content,
.darkTheme .rs-table-row-header,
.darkTheme .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
.darkTheme .siteChangelogRow,
.darkTheme .tableHeader,
.darkTheme .customTagBox {
  background-color:#212529 !important;
}
.darkTheme .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover .rs-table-cell,
.darkTheme .rs-btn-icon-with-text > .rs-icon,
.darkTheme .custom_panel_button,
.darkTheme .rs-btn-disabled,
.darkTheme .global-time-filter,
.darkTheme .rs-picker-popup,
.darkTheme .rs-picker-tag,
.darkTheme .color-picker
{
  background-color:#2c3034;
}

.darkTheme .rs-picker-cascader-row,
.darkTheme .card-footer .rs-radio-group-picker .rs-radio-checked .rs-radio-checker,
.darkTheme .selectedElement,
.darkTheme .skipTimeButton,
.darkTheme .rs-picker-input-group {
  background-color:#212529 !important;
}

.darkTheme .react-select__option--is-selected{
  background-color:#000000;
}
.darkTheme .rs-popover-arrow:after{
  border-bottom-color: #141414 !important;
}
/* .darkTheme .dropzone-file-item .file-item,
.darkTheme .tagTablename div:hover,
.darkTheme .pro-sidebar-inner,
.darkTheme  .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper,
.darkTheme .pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner{
  background-color:#1f1f1f;
} */
.darkTheme .timebuttons label,
.darkTheme .timebuttons .timeButton,
.darkTheme .boa-checkbox input:checked ~.checkmark,
.darkTheme .rs-toggle-checked .rs-toggle-presentation {
  background-color:#004484;
}

.darkTheme .customInputField .selected,
.darkTheme .timebuttons .selected{
  background-color: #00305c !important;
}
.darkTheme .metadata-hide-btn:hover,
.darkTheme .react-select__control:not(.navbar .react-select__control),
.darkTheme .siteChangelogHighlightedRow {
  background-color:#3f4346;
}
.darkTheme .checkmark{
  background-color:gray;
}
.darkTheme .ci-stage-button{
  background-color: #0065a5;
}
.darkTheme .btn-success{
  background-color:#23923d;
}
.darkTheme .btn-danger{
  background-color:#e40622;
}
.darkTheme .orange{
  background-color:#e68900;
}
.darkTheme .green{
  background-color:#41c100;
}
.darkTheme .yellow{
  background-color:#a7b300;
}
.darkTheme .rs-picker-input{
  background-color: transparent;
}
/* .darkTheme .rs-picker-select-menu-items .rs-picker-menu-group-title:hover{
  background-color: rgba(204,233,255,.5);
} */
.darkTheme .card-footer {
  background-color: rgba(0,0,0,.03);
}
/*-------TEXT COLORS-------*/
.darkTheme .productionControlContent .table,
.darkTheme .productionControlContent .card-body,
.darkTheme .productionControlContent .textbox,
.darkTheme .table thead,
.darkTheme .selectedTagsList,
.darkTheme .tagTablename:hover,
.darkTheme #tagTable,
.darkTheme .cuttingTools,
.darkTheme .footerBtnGroup,
.darkTheme .rs-modal-title,
.darkTheme .rs-modal-header-close,
.darkTheme .timebuttons ::placeholder,
.darkTheme .tagAnalyze table,
.darkTheme .analysis-drawer-title-container,
.darkTheme .pro-sidebar,
.darkTheme .file-item,
.darkTheme .y-axis-toggle-button,
.darkTheme .breadcrumb-icon,
.darkTheme .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:focus,
.darkTheme .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon,
.darkTheme .rs-picker-menu,
.darkTheme .rs-picker-menu-group-title,
.darkTheme .rs-picker-select-menu-item,
.darkTheme .rs-popover,
.darkTheme .rs-input,
.darkTheme .header-dropdown-item-description,
.darkTheme .header-selection div,
.darkTheme .changelog-entry h4,
.darkTheme .statusBlockList,
.darkTheme .highcharts-tooltip span,
.darkTheme .highcharts-line-series span,
.darkTheme .rs-picker-toggle-value,
.darkTheme .rs-picker-cascader-menu-item,
.darkTheme .rs-picker-cascader-row-focus,
.darkTheme .rs-picker-cascader-row,
.darkTheme .rs-picker-search-bar-input,
.darkTheme .rs-tag,
.darkTheme .rs-table-header-row-wrapper,
.darkTheme .rs-table-cell,
.darkTheme .rs-radio-checker label,
.darkTheme .skipTimeButton i,
.darkTheme .highcharts-label span,
.darkTheme .rs-radio-group,
.darkTheme .card-body
{
  color:#e0e0e0 !important;
}
.darkTheme .menu-toggle-button,
.darkTheme .fileman-breadcrumb,
.darkTheme .rs-radio-inline label,
.darkTheme .pro-sidebar .pro-menu .pro-inner-item,
.darkTheme .pro-sidebar .pro-menu a,
.darkTheme .react-select__single-value:not(.navbar .react-select__single-value),
.darkTheme .react-select__placeholder,
.darkTheme .statusBlockButton,
.darkTheme .toggleSeriesBtn span,
.darkTheme .disabledSiteInfo span,
.darkTheme .rs-notification-content .rs-notification-title-with-icon,
.darkTheme .rs-notification-content .rs-notification-description,
.darkTheme .white-icon,
.darkTheme .rs-icon,
.darkTheme .rs-drawer-title,
.darkTheme .rs-checkbox-label,
.darkTheme .custom_panel_button,
.darkTheme .rs-picker-value-item,
.darkTheme .rs-input-group-addon,
.darkTheme .rs-btn-default,
.darkTheme .rs-stack-item *, 
.darkTheme .rs-cascade-tree-item,
.darkTheme .tagPickerExtraFooter  {
  color:#e0e0e0;
}
.darkTheme .form-control,
.darkTheme ::placeholder
{
  color:#202020;
}
.darkTheme h1:not(.welcomeText),
.darkTheme h2,
.darkTheme .weather,
.darkTheme .boa-card-header,
.darkTheme .breadcrumb-link,
.darkTheme .breadcrumb,
.darkTheme .react-select__menu,
.darkTheme .docviewer p,
.darkTheme .docviewer li,
.darkTheme .toc_link,
.darkTheme .toc_bar,
.darkTheme .rs-dropdown button,
.darkTheme .rs-dropdown li,
.darkTheme .rs-input-group input::placeholder,
.darkTheme .rs-input-group input,
.darkTheme .tagTablename,
.darkTheme .rs-modal-content,
.darkTheme .embedded-docs-content
.darkTheme h1,
.darkTheme h2,
.darkTheme h3,
.darkTheme .metadata-container,
.darkTheme .embedded-docs-container,
.darkTheme .rs-panel-title,
.darkTheme .fileman-breadcrumb .rs-btn,
.darkTheme #toolbar button,
.darkTheme .ql-stroke,
.darkTheme .global_time_filter .rs-btn,
.darkTheme .more-button-container,
.darkTheme .rs-input::placeholder
{
  color:#e0e0e0 !important;
} 
.darkTheme .background-logo-text,
.darkTheme a:hover:not(.navbar-brand),
.darkTheme .card{
  color: #404040;
}
.darkTheme .highcharts-legend-item-hidden span{
  color: #2c3034 !important;
}

.darkTheme input.rs-input[type="number"]:disabled {
  color: #1a1c1e !important;
}
.darkTheme .pro-sidebar .pro-menu .pro-menu-item.active,
.darkTheme .pro-sidebar .pro-menu .pro-inner-item:hover,
.darkTheme .pro-sidebar .pro-menu a:hover,
.darkTheme .menu-toggle-button:hover,
.darkTheme .rs-dropdown-item:hover,
.darkTheme .rs-picker-cascader-search-match {
  color: #0075be !important;
}
.darkTheme .Subtitle_Andritz,
.darkTheme a{
  color:#006db0;
}
.darkTheme .card-footer button:hover,
.darkTheme .rs-radio-group-picker .rs-radio-checker label:hover,
.darkTheme .footerBtnGroup .rs-radio .rs-radio-checker label:hover {
  color: #1675e0 !important;
}

.hoverRed:hover {
  color: #dc3545;
}
/*-------BORDERS-------*/
.darkTheme .timebuttons label{
  border: solid 1px #004484; 
}
.darkTheme .timebuttons input[type="radio"]:checked + label,
.darkTheme .customInputField .selected{
  border: solid 1px #00305c;
}

.darkTheme .rs-drawer-header-close{
  border: none;
}

.darkTheme .rs-input-number-touchspin-up, 
.darkTheme .rs-input-number-touchspin-down{
  border: none !important;
}
.darkTheme table thead {
  border-bottom:2px solid #555555;
}
.darkTheme .breadcrumb,
.darkTheme .card-title,
.darkTheme .border-bottom,
.darkTheme .tab-buttons,
.darkTheme .pro-menu li,
.darkTheme .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  border-bottom:1px solid #555555 !important;
}
.darkTheme .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item,
.darkTheme .metadata-container,
.darkTheme .rs-panel-group > .rs-panel + .rs-panel::before
{
  border-top:1px solid #555555;
}
.darkTheme .pro-menu {
  border-right:1px solid #555555 !important;
}


.darkTheme .left-divider,
.darkTheme .rs-radio-group-picker .rs-radio-checker > label {
  border-left: 1px solid #555555 !important;
}

.darkTheme .react-select__control,
.darkTheme .react-select__menu,
.darkTheme .react-select__single-value,
.darkTheme .rs-input-group,
.darkTheme .menu-toggle-button,
.darkTheme .statusBlockButton,
.darkTheme .tagPickerExtraFooter .search_btn,
.darkTheme .tagPickerExtraFooter .rs-radio-group,
.darkTheme .rs-btn,
.darkTheme .rs-radio-group-picker,
.darkTheme .global-time-filter,
.darkTheme .color-picker,
.darkTheme .rs-input,
.darkTheme .rs-btn-default,
.darkTheme .rs-picker-input,
.darkTheme .tagPickerExtraFooter,
.darkTheme .header-select__control
{
  border-color:#555555;
}

.darkTheme .pro-menu li:nth-last-child(1) {
  border-bottom: 0 !important;
}

@media screen and (max-width: 768px) {
 .darkTheme .tagsearch .rs-radio-inline {
    border-top: 1px solid #555555;
  }
}
/*-------OPACITY-------*/
.darkTheme .overview {
  opacity:0.9;
}
.darkTheme .statusMonitor .table,
.darkTheme .siteSelectionMap{
  opacity:0.85;
}
.darkTheme .productionControlContent{
  opacity:0.8;
}
.darkTheme .backgroundImg{
  opacity:0.7;
}

.darkTheme .andritz-logo{
  opacity:0.18;
}
/*-------Z-INDEX-------*/
.darkTheme .overview {
  z-index:10;
}
/*-------FONTS-------*/
.darkTheme .font-weight-bold{
  font-weight:normal !important;
}
/*-------Box-shadows-------*/
.darkTheme .file-item:hover {
  box-shadow: 0 0 4px 2px rgb(18 18 18);
}

.darkTheme .metadata-container,
.darkTheme .navbar {
  box-shadow: 0 0 3px 2px #3f4346;
}
.darkTheme .item {
  box-shadow: 1px 1px 3px rgb(18 18 18);
}
.darkTheme .navbar-header,
.darkTheme .breadcrumb {
  box-shadow: none;
}
.darkTheme .menu-toggle-button,
.darkTheme .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon,
.darkTheme .statusBlockButton, 
.darkTheme .statusBlockList,
.darkTheme .file-item,
.darkTheme .menu-toggle-button{
  box-shadow: 1px 1px 3px rgba(116, 124, 125, 0.45) !important;
}

.darkTheme .pro-sidebar {
  border-right: 1px solid #555555;
}
/*-------HIGHCHARTS-------*/
.darkTheme .highcharts-axis-line,
.darkTheme .highcharts-xaxis .highcharts-tick,
.darkTheme .highcharts-yaxis-grid .highcharts-grid-line
{
  stroke:#404040;
}
.darkTheme .highcharts-dial,
.darkTheme .highcharts-pivot,
.darkTheme .highcharts-label text,
.darkTheme .highcharts-legend-item text,
.darkTheme .btn-danger,
.darkTheme .btn-success,
.darkTheme .ci-stage-button,
.darkTheme .highcharts-title,
.darkTheme .highcharts-xaxis-labels text,
.darkTheme .highcharts-legend-item text,
.darkTheme .highcharts-subtitle,
.darkTheme .highcharts-radial-axis-labels text,
.darkTheme .highcharts-legend-item text,
.darkTheme .highcharts-caption
{
  fill:#e0e0e0 !important;
  color:#e0e0e0 !important;
  font-weight:normal !important;
}

.darkTheme .highcharts-axis-labels.highcharts-yaxis-labels text,
.darkTheme .highcharts-axis-title, .highcharts-title {
  fill:#e0e0e0;
}

.darkTheme .highcharts-text-outline{
  fill:#000 !important;
  stroke: #000 !important;
}

.darkTheme .highcharts-background,
.darkTheme .highcharts-legend-box,
.darkTheme .highcharts-label-box{
  fill: transparent;
}

.darkTheme .highcharts-label-box{
  fill: #2c3034;
}

.darkTheme .rs-btn-disabled * {
  fill: #1a1c1e  !important;
}

.darkTheme .highcharts-axis-line,
.darkTheme .highcharts-xaxis .highcharts-tick{
  stroke: transparent;
}

.darkTheme .ql-stroke{
  stroke: #c8c8c8;
}

.darkTheme .rs-list-item,
.darkTheme .rs-list {
  outline: 1px solid #2c3034;
}

.darkTheme .table {
  --bs-table-bg: #212529;
}

.darkTheme .rs-btn-default {
  background-color: #2c3034;
  opacity: 1;

}

.darkTheme .rs-panel-btn {
  transition: background-color 0.2s ease;
  background-color: #2c3034;
}

.darkTheme .rs-btn-default:hover,
.darkTheme .rs-panel-btn:hover {
  background-color: #3d4348;
  opacity: 1;
}

.darkTheme .rs-toast {
  background-color: #2c3034;
  color: white;
}

.darkTheme .rs-date-range-input {
  border-radius: 0;
}

.darkTheme .embedded-docs-iframe,
.darkTheme .embedded-docs-menu-toggle,
.darkTheme .embedded-docs-menu-toggle .toggle-menu-button  {
  background-color: #2c3034;
}
.darkTheme .embedded-docs-menu-toggle .toggle-menu-button  {
  box-shadow: 1px 1px 3px rgba(116, 124, 125, 0.45);
}